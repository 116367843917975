import React, { Fragment, useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import '../../../assets/css/App.css';
import jwt_decode from "jwt-decode";
import FooterComponent from '../../../shared/Footer';
import HeaderComponent from '../../../shared/Header';
import Profile from '../Profile';
import Login from '../Login';

const AdminComponent = () => {

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [permisos, setPermisos] = useState([]);
    const [tienePermisos, setTienePermisos] = useState(true);

    useEffect(() => {

        const getUserMetadata = async () => {
            const domain = "vendors.elcuatro.com";
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: `https://${domain}/auth0/api`,
                    scope: "read:current_user"
                });

                var decoded = jwt_decode(accessToken);

                if (decoded.permissions.length > 0) {
                    setPermisos(decoded.permissions);
                    let encontrado = decoded.permissions.find(p => p.toLowerCase().includes('access:vendors'));
                    if (encontrado) {
                        console.log('tiene permisos');
                        setTienePermisos(true);
                    } else {
                        console.log('no tiene permisos');
                    }
                }
            } catch (e) {
                console.error("error",e.message);
            }
        };
        getUserMetadata();
    }, [getAccessTokenSilently]);


    return (
        <Fragment>
            {
                isAuthenticated && (
                    tienePermisos ?
                        <div>
                            <HeaderComponent isAdmin={true} />
                            <Profile />
                            <FooterComponent />
                        </div>
                        :
                        <Login />
                )
            }
        </Fragment>
    )
}


export default AdminComponent