import React, { Fragment } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import HeaderComponent from "../../shared/Header";
import FooterComponent from "../../shared/Footer";

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Fragment>
      <HeaderComponent />
      <div className="container">
        <div className="row">
          <div className="col-lg-4 pt-5 mx-auto">
            <div className="card text-white bg-secondary mb-3 text-start">
              <div className="card-header">Bienvenido al Sistema de Proveedores</div>
              <div className="card-body">
                <h2 className="card-title">Iniciar sesión</h2>
                <p className="card-text">Ingrese con su cuenta de correo de elcuatro.com</p>
                <div className=" text-center">
                  <button className="col-12 btn btn-primary btn-lg m-1 btn-block" onClick={() => loginWithRedirect()}>Iniciar sesión</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <FooterComponent/>
          </div>
        </div>
      </div>
    </Fragment>
  );

};

export default Login;