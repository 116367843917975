import React, { Fragment } from "react";
import '../../assets/css/App.css'
import { useState } from "react";

const DocumentacionProveedor = (props) => {

    const { eventEmmiterLista } = props
    const [listaDocumentos, setListaDocumentos] = useState(props.listaDoc);
    const tamañoMinArchivo = 4000000;

    const verDocumentacion = (event, categoria, id) => {
        let files = event.target.files;
        if (files.length === 1 && files[0].size <= tamañoMinArchivo && ['application/pdf', '.doc', '.docx', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(files[0].type)) {
            let pos = listaDocumentos.findIndex(d => d.categoria === categoria);
            if (pos !== -1) {
                let lista = listaDocumentos;
                let documento = lista[pos];
                documento.nombre_archivo = files[0]?.name;
                documento.archivo = files[0];
                documento.estado = true;
                lista[pos] = documento;
                setListaDocumentos((preLista) => preLista = lista.slice());
                eventEmmiterLista(listaDocumentos);
            }
        } else {
            var inputImage = document.getElementById("form" + id.toString());
            inputImage.value = '';

            let pos = listaDocumentos.findIndex(d => d.categoria === categoria);
            if (pos !== -1) {
                let lista = listaDocumentos;
                let documento = lista[pos];
                documento.nombre_archivo = '';
                documento.archivo = null;
                documento.estado = false;
                lista[pos] = documento;
                setListaDocumentos((preLista) => preLista = lista.slice());
            }
        }
    }


    const getDocumentacionProveedor = (doc) => {
        return listaDocumentos.find(d => d.categoria === doc.categoria);
    }

    const eliminarDocumento = (value) => {
        let pos = listaDocumentos.findIndex(e => e.id === value.id);
        let lista = listaDocumentos.slice();
        lista[pos].estado = false;
        lista[pos].archivo = null;
        lista[pos].nombre_archivo = '';
        lista[pos].ruta_archivo = '';
        lista[pos].updated_at = null;
        lista[pos].created_at = null;
        setListaDocumentos(lista.slice());
        eventEmmiterLista(listaDocumentos);
    }

    const tranformarPropiedad = (key) => {
        let nuevaKey = '';
        switch (key.toString().toUpperCase()) {
            case 'CONVENIO_CM':
                return 'CONVENIO MULTILATERAL'
            case 'CERTIFICADO DE NO RETENCION DE IMPUESTOS':
                return 'CERTIFICADO DE NO RETENCIÓN DE IMPUESTOS'
            case 'IIBB':
                return 'CONSTANCIA DE INSC. INGRESOS BRUTOS'
            case 'AFIP':
                return 'CONSTANCIA DE INSC. AFIP'
            default:
                nuevaKey = key.toString().split("_").join(' ');
                nuevaKey = nuevaKey.trim();
                return nuevaKey.toString().toUpperCase();
        }
    }

    return (
        <Fragment>
            <div className="container text-start">
                <div className="row m-0 d-flex justify-content-start text-start">
                    <div className="col-sm-12 col-md-12 col-lg-12 my-3 p-1 border-bottom">
                        <h2>Documentación adjunta</h2>
                        <p className="text-muted">Sólo se permiten archivos en PDF o DOCX, con tamaño máximo de 4Mb cada uno.</p>

                    </div>
                    {listaDocumentos.length > 0 && listaDocumentos.map((value, index) =>
                        <div key={index} className='col-sm-12 col-md-12 col-lg-12 my-2 me-3 ps-0'>
                            {!value.archivo ?
                                <div key={index} className="col-sm-12 col-md-12 col-lg-12 my-2">
                                    <label htmlFor={'form' + value.id?.toString()} className="form-label">Archivo {tranformarPropiedad(value.categoria)}</label>
                                    <input accept="application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" className="form-control form-control-lg" id={'form' + value.id?.toString()} type="file"
                                        onChange={(event) => verDocumentacion(event, value.categoria, value.id)}
                                    />
                                    {props.erroresDocs.length > 0 && props.erroresDocs.find(e => e.id === value.id) &&
                                        <div className="invalid-feedback d-block" role="alert">
                                            <strong>Este archivo es requerido</strong>
                                        </div>
                                    }
                                </div>
                                :
                                <div className="col-sm-12 col-md-12 col-lg-12 my-2 mx-1">
                                    <div className="card rounded shadow col-sm-12 col-md-12 col-lg-12" >
                                        <div className="card-body">
                                            <ul className="list-group list-group-flush">
                                                <li key={index} className="list-group-item d-flex justify-content-start m-1 align-items-center">
                                                    <button className='btn btn-danger' onClick={() => eliminarDocumento(getDocumentacionProveedor(value))}>Eliminar</button>
                                                    <h5 className='card-subtitle mx-2'> {tranformarPropiedad(getDocumentacionProveedor(value)?.categoria)}</h5>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    )}
                </div>

            </div>
        </Fragment>
    );
};

export default DocumentacionProveedor;