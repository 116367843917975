import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'

import { Button } from "reactstrap";
import ModalComponent from '../../components/ModalComponent';

import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from "../../hook/useForm";
import HeaderComponent from '../../shared/Header';
import logoPais from '../../assets/images/argentina.png';
import axios from 'axios';
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import DocumentacionProveedor from './DocumentacionProveedor';
import FormFormaPagoComponent from './FormFormaPago';
import VistaPreviaProveedor from './VistaPreviaProveedor';
import imagenChecked from '../../assets/images/checked.png';
import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Loader from '../../components/Loader';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Proveedor', 'Documentación', 'Forma de Pago', 'Confirmar y Enviar'];
}

const initialFormProveedor = {
    id: null,
    pais_id: null,
    cuit: '',
    razon_social: '',
    rubro_actividad_id: '',
    domicilio_comercial: '',
    nombre_contacto: '',
    cod_area: '',
    nro_telefono: '',
    email: '',
    condicion_iva: '',
    contribuyente_cm: 0,
    agente_percepcion_iva: 0,
    agente_percepcion_iibb: 0,
    agente_percepcion_iibb_descripcion: '',
    ingresos_brutos: '',
    created_at: new Date(),
    updated_at: new Date()
};

const endpoint = process.env.REACT_APP_BACKEND_URL;

const FormProveedorComponent = () => {

    const [listaDocumentos, setListaDocumentos] = useState([]);
    const [listaFormasDePago, setListaFormasDePago] = useState([]);

    const { id } = useParams();
    const [idPais] = useState(1); // argentina
    const [paisElegido, setPaisElegido] = useState();
    const [textButtonConfirmar, setTextButtonConfirmar] = useState('Confirmar y Enviar');
    const [deshabilitarBotonConfirmar, setDeshabilitarBotonConfirmar] = useState(false);

    const [dataModal, setDataModal] = useState(null);


    useEffect(() => {

        getListasSelect();

        if (+id !== 0) {
            getProveedor(id);
        } else {
            getPais(idPais);
        }

    }, [])

    //obtener datos
    const navigate = useNavigate();
    const getListasSelect = async () => {
        let response = await axios.get(`${endpoint}/sysconfigs/clave/condiciones-iva`);
        let config = JSON.parse(response.data[0].valor);

        setListaCondicionesIva(prevLista => prevLista = config.values.slice());
        response = await axios.get(`${endpoint}/sysconfigs/clave/tipos-cuentas`);
        config = JSON.parse(response.data[0].valor);

        setListaTipoDeCuentas(prevLista => prevLista = config.values.slice());

        if (+id === 0) {
            response = await axios.get(`${endpoint}/sysconfigs/clave/categorias-documentacion`);
            config = JSON.parse(response.data[0].valor);

            setListaCategoriasDocumentacion(prevLista => prevLista = config.values.slice());

            //crear la lista de documentos
            let listadocumentosGenerada = [];
            config.values.forEach((nombreCategoria, index) => {
                listadocumentosGenerada.push(
                    { id: index + 1, proveedor_id: null, nombre_archivo: '', archivo: null, ruta_archivo: '', categoria: nombreCategoria, created_at: new Date(), updated_at: new Date(), estado: false }
                );
            });
            setListaDocumentos(prevLista => prevLista = listadocumentosGenerada.slice())
        }

        response = await axios.get(`${endpoint}/actividades`);
        setListaRubrosActividades(prevLista => prevLista = response.data);

        response = await axios.get(`${endpoint}/sysconfigs/clave/bancos-tradicionales`);
        config = JSON.parse(response.data[0].valor);

        setListaBancosTradicionales(prevLista => prevLista = config.values.sort().slice());
        response = await axios.get(`${endpoint}/sysconfigs/clave/bancos-digitales`);
        config = JSON.parse(response.data[0].valor);

        setListaBancosDigitales(prevLista => prevLista = config.values.slice());
    }

    const getPais = async (id) => {
        const response = await axios.get(`${endpoint}/paises/${id}`);
        let pais = response.data;
        if (pais) {
            setPaisElegido(pais);
            form.pais_id = pais.id;
        }
    }


    const getProveedor = async (id) => {
        const response = await axios.get(`${endpoint}/proveedores/${id}`);
        let proveedor = response.data;
        if (proveedor) {
            if (id !== 0 && !proveedor.confimado && proveedor.codigo_confirmacion) {
                //no confirmo su correo
                navigate("/");
            } else {
                for (const key in proveedor) {
                    if (proveedor[key] === null) {
                        proveedor[key] = ''
                    }
                }
                setPaisElegido(proveedor.pais);
                delete proveedor.pais;
                Object.assign(form, proveedor);
                getDocumentacionDelProveedor(id);
            }

        }
    }

    const [listaDocumentosProveedorOriginal, setListaDocumentosProveedorOriginal] = useState([]);
    const [listaFormasDePagoProveedorOriginal, setListaFormasDePagoProveedorOriginal] = useState([]);

    const getDocumentacionDelProveedor = async (idProveedor) => {

        const response = await axios.get(`${endpoint}/documentacion/proveedor/${idProveedor}`);
        setListaDocumentosProveedorOriginal(response.data);
        let listaDocActualesCargados = response.data;

        //obtengo del system 
        let responseCategorias = await axios.get(`${endpoint}/sysconfigs/clave/categorias-documentacion`);
        let config = JSON.parse(responseCategorias.data[0].valor);
        setListaCategoriasDocumentacion(prevLista => prevLista = config.values.slice());

        //crear la lista de documentos generada
        let listadocumentosGenerada = [];
        config.values.forEach((nombreCategoria, index) => {
            listadocumentosGenerada.push(
                { id: index + 1, proveedor_id: null, nombre_archivo: '', archivo: null, ruta_archivo: '', categoria: nombreCategoria, created_at: new Date(), updated_at: new Date(), estado: false }
            );
        });
        setListaDocumentos(prevLista => prevLista = listadocumentosGenerada.slice())
        let listaDocs = listadocumentosGenerada;

        //asigno los documentos que ya tiene el proveedor a la lista de documentos generada
        listaDocActualesCargados.forEach(doc => {
            let pos = listaDocs.findIndex(d => d.categoria.toUpperCase() === doc.categoria.toUpperCase());
            if (pos !== -1) {
                Object.assign(listaDocs[pos], doc);
                listaDocs[pos].estado = true;
                listaDocs[pos].archivo = doc;
            }
        });
        setListaDocumentos((prevLista) => prevLista = listaDocs);
        getFormasPagosDelProveedor(id);

    }
    const getFormasPagosDelProveedor = async (idProveedor) => {
        const response = await axios.get(`${endpoint}/formapagos/proveedor/${idProveedor}`);
        setListaFormasDePago((prevLista) => prevLista = response.data);
        setListaFormasDePagoProveedorOriginal((lista) => lista = response.data.slice());
    }


    const [listaCondicionesIva, setListaCondicionesIva] = useState([])
    const [listaRubrosActividades, setListaRubrosActividades] = useState([]);
    const [listaBancosTradicionales, setListaBancosTradicionales] = useState([]);
    const [listaBancosDigitales, setListaBancosDigitales] = useState([]);
    const [listaTipoDeCuentas, setListaTipoDeCuentas] = useState([]);
    const [listaCategoriasDocumentacion, setListaCategoriasDocumentacion] = useState([]);

    //PASO A PASO
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const [listaErroresDocs, setListaErroresDocs] = useState([]);
    const handleNext = (activeStep) => {
        switch (activeStep) {
            case 0:
                setErrorsForm(validationsForm(form));
                if (Object.keys(validationsForm(form)).length === 0) {
                    let listaDocSinArchivos = [];
                    setListaErroresDocs(prevList => prevList = listaDocSinArchivos.splice());
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }

                break;

            case 1:
                let listaDocSinArchivos = [];
                setListaErroresDocs(prevList => prevList = listaDocSinArchivos.splice());

                //archivos que son obligatorios
                let listaCategoriaObligatorias = [];

                if (+form.contribuyente_cm === 1) {
                    listaCategoriaObligatorias.push('CONVENIO_CM');
                }

                listaCategoriaObligatorias.push('IIBB');
                listaCategoriaObligatorias.push('AFIP');


                let contDocObligatorios = 0;
                listaDocumentos.forEach(doc => {
                    if (listaCategoriaObligatorias.includes(doc.categoria)) {
                        if (!doc.archivo) {
                            listaDocSinArchivos.push(doc);
                        } else {
                            contDocObligatorios++;
                        }
                    }
                });
                setListaErroresDocs(prevList => prevList = listaDocSinArchivos.slice());

                if (listaCategoriaObligatorias.length === contDocObligatorios) {
                    //esta OK
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }

                break;
            case 2:
                if (listaFormasDePago.length > 0) {
                    //esta OK
                    if (+id !== 0) {
                        setTextButtonConfirmar('Guardar cambios');

                    }
                    setDataModal(null);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }

                break;
            default:
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
        }

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    //TOKEN REACHAPCHAT V3
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [token, setToken] = useState('');
    const [actionToChange, setActionToChange] = useState('');

    const registerUser = useCallback(async () => {
      
        if (!executeRecaptcha) {
            return;
        }
        const result = await executeRecaptcha('register');
        setToken(result);
        
    }, [executeRecaptcha]);

    const handleReCaptchaVerify = async () => {
        const token = await executeRecaptcha('register');
        setToken(token);
    };

    const handleTextChange = useCallback(event => {
        setActionToChange(event.target.value);
    }, []);

    //FORMULARIO PROVEEDOR

    const {
        form,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useForm(initialFormProveedor);

    const [errorsForm, setErrorsForm] = useState({});
    const validationsForm = (form) => {
        let errors = {};
        //let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
        let regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/;
        let regCantidadCaracteresLargo = /^.{1,100}$/;
        let regCantidadCaracteresMedio = /^.{1,10}$/;
        let regCantidadCaracteresCorto = /^.{1,5}$/;
        let regCuitLongitud = /^.{10,11}$/;
        let regNumeros = /^([0-9])*$/;

        if (!form.cuit.trim()) {
            errors.cuit = "El campo CUIT es requerido";
        } else if (!regNumeros.test(form.cuit.trim())) {
            errors.cuit = 'El campo CUIT solo permite numeros';
        }
        else if (!regCuitLongitud.test(form.cuit.trim())) {
            errors.cuit = 'El campo CUIT tiene que tener 11 digitos';
        }
        if (!form.razon_social.trim()) {
            errors.razon_social = "El campo Razón Social es requerido";
        } else if (!regCantidadCaracteresLargo.test(form.razon_social.trim())) {
            errors.razon_social = "El campo Razón Social no debe superar 100 caracteres";
        }
        else if (form.razon_social.trim().length < 5) {
            errors.razon_social = 'El campo Razón Social debe tener mas caracteres.';
        }
        if (!form.domicilio_comercial.trim()) {
            errors.domicilio_comercial = "El campo Domicilio comercial es requerido";
        } else if (!regCantidadCaracteresLargo.test(form.domicilio_comercial.trim())) {
            errors.domicilio_comercial = "El campo Domicilio comercial no debe superar 100 caracteres";
        }
        else if (form.domicilio_comercial.trim().length < 5) {
            errors.domicilio_comercial = 'El campo Domicilio comercial debe tener mas caracteres.';
        }
        if (!form.nombre_contacto.trim()) {
            errors.nombre_contacto = "El campo Nombre de contacto es requerido";
        } else if (!regCantidadCaracteresLargo.test(form.nombre_contacto.trim())) {
            errors.nombre_contacto = "El campo Nombre de contacto no debe superar 100 caracteres";
        }
        else if (form.nombre_contacto.trim().length < 5) {
            errors.nombre_contacto = 'El campo Nombre de contacto debe tener mas caracteres.';
        }
        if (!form.cod_area.trim()) {
            errors.cod_area = "El campo Cod. de Área es requerido";
        } else if (!regNumeros.test(form.cod_area.trim())) {
            errors.cod_area = 'El campo Cod. de Área solo permite numeros';
        }
        else if (!regCantidadCaracteresCorto.test(form.cod_area.trim())) {
            errors.cod_area = "El campo Cod. de Área no debe superar 5 caracteres";
        }

        if (!form.nro_telefono.trim()) {
            errors.nro_telefono = "El campo Nro de teléfono es requerido";

        }
        else if (!regNumeros.test(form.nro_telefono.trim())) {
            errors.nro_telefono = 'El campo Nro de teléfono solo permite numeros';
        }
        else if (!regCantidadCaracteresMedio.test(form.nro_telefono.trim())) {
            errors.nro_telefono = "El campo Nro de teléfono no debe superar 10 caracteres";
        }

        if (!form.rubro_actividad_id.toString().trim() || form.rubro_actividad_id.toString().trim().toLowerCase().includes('seleccionar')) {
            errors.rubro_actividad_id = "El campo Rubro/Actividad es requerido";
        }

        if (!form.condicion_iva.trim() || form.condicion_iva.trim().toLowerCase().includes('seleccionar')) {
            errors.condicion_iva = "El campo condición iva es requerido";
        }
        if (!form.email.trim()) {
            errors.email = "El campo Correo electrónico es requerido";
        } else if (!regexEmail.test(form.email.trim())) {
            errors.email = "El campo Correo electrónico es incorrecto";
        }

        if (!form.ingresos_brutos.trim()) {
            errors.ingresos_brutos = "El campo Nro Inscripción de Ingresos Brutos es requerido";

        } else if (!regNumeros.test(form.ingresos_brutos.trim())) {
            errors.ingresos_brutos = 'El campo Nro Inscripción de Ingresos Brutos solo permite numeros';
        }

        if (form.agente_percepcion_iibb === '1') {
            if (!form.agente_percepcion_iibb_descripcion.trim()) {
                errors.agente_percepcion_iibb_descripcion = "El campo Descripción de Jurisdicciones es requerido";
            } else if (!regCantidadCaracteresLargo.test(form.agente_percepcion_iibb_descripcion.trim())) {
                errors.agente_percepcion_iibb_descripcion = "El campo Ingresos Brutos no debe superar 100 caracteres";
            }
        }

        return errors;
    };

    const [contDocumentosSubidos, setContDocumentosSubidos] = useState(0);
    const [cantDocumentosASubir, setCantDocumentosASubir] = useState(0);
    const [contFormasPago, setContFormasPago] = useState(0);
    const [cantFormasPagoASubir, setCantFormasPagoASubir] = useState(0);

    const enviarYConfirmar = async () => {

        setContDocumentosSubidos(0);
        setContFormasPago(0);
        setCantDocumentosASubir(0);

        setDeshabilitarBotonConfirmar(true);
        setDataModal(null);

        let formularioProveedor = { codigo_confirmacion: '', confirmado: false, created_at: new Date(), updated_at: new Date(), token: token };
        //obtengo el token recapcha
        await executeRecaptcha('register').then(responseToken => {

            setToken(prevToken => prevToken = responseToken);
            formularioProveedor.token = responseToken;

            formularioProveedor = Object.assign(formularioProveedor, form);
            formularioProveedor.agente_percepcion_iva = +formularioProveedor.agente_percepcion_iva;
            formularioProveedor.agente_percepcion_iibb = +formularioProveedor.agente_percepcion_iibb;
            formularioProveedor.rubro_actividad_id = +formularioProveedor.rubro_actividad_id;
            formularioProveedor.pais_id = paisElegido.id;

            if (+id !== 0) {
                //ACTUALIZANDO
                formularioProveedor.updated_at = new Date();
                setTextButtonConfirmar('Guardando...');
                axios.put(`${endpoint}/proveedores/${formularioProveedor.id}`, formularioProveedor).then(response => {
                    if (response.data.res === true) {
                        //verificamos los medios de pagos
                        let listaNoEliminarFP = [];
                        let listaSiAgregarFP = [];
                        let listaAEliminarFP = [];

                        listaFormasDePagoProveedorOriginal.forEach(d => {
                            let encontrado = listaFormasDePago.find(doc => doc.hasOwnProperty('id') && doc.id === d.id);
                            if (encontrado) {
                                listaNoEliminarFP.push(d);
                            } else {
                                listaAEliminarFP.push(d);
                            }
                        });
                        listaSiAgregarFP = listaFormasDePago.filter(doc => !doc.hasOwnProperty('id'));

                        //elimino los que fp que ya no tiene que estar en  bd
                        if (listaAEliminarFP.length > 0) {
                            listaAEliminarFP.forEach(async (fp) => {
                                await axios.delete(`${endpoint}/formapagos/${fp.id}`).then(responsefp => {
                                    setContFormasPago(prevCont => prevCont = prevCont + 1);
                                });
                            });
                        }

                        //forma de pago hay para agregar

                        if (listaSiAgregarFP.length > 0) {
                            listaSiAgregarFP.forEach(async (fp) => {
                                fp.proveedor_id = formularioProveedor.id;
                                await axios.post(`${endpoint}/formapagos`, fp).then(responsefp => {
                                    setContFormasPago(prevCont => prevCont = prevCont + 1);
                                });

                            });
                        }
                        setCantFormasPagoASubir(cantprev => cantprev = listaAEliminarFP.length + listaSiAgregarFP.length);

                        //verfico los documentos
                        let listaNoEliminar = [];
                        let listaEliminar = [];
                        let listaEliminarActualizar = [];
                        let listaDocAgregar = [];

                        listaDocumentosProveedorOriginal.forEach(d => {
                            let encontrado = listaDocumentos.find(doc => doc.nombre_archivo === d.nombre_archivo && doc.ruta_archivo === d.ruta_archivo);
                            if (encontrado) {
                                listaNoEliminar.push(d);
                            }
                            encontrado = listaDocumentos.find(doc => doc.categoria.toLowerCase() === d.categoria.toLowerCase() && doc.estado === false && doc.nombre_archivo === '' && !doc.archivo && doc.ruta_archivo === '');
                            if (encontrado) {
                                listaEliminar.push(d);
                            }
                            encontrado = listaDocumentos.find(doc => doc.categoria.toLowerCase() === d.categoria.toLowerCase() && doc.estado === true && doc.nombre_archivo !== '' && doc.nombre_archivo !== d.nombre_archivo
                                && doc.archivo && doc.ruta_archivo === '');
                            if (encontrado) {
                                listaEliminarActualizar.push(d);
                            }

                        });

                        listaDocAgregar = listaDocumentos.filter(d => d.archivo && d.proveedor_id === null && d.estado === true && d.nombre_archivo !== '' && d.ruta_archivo === '');
                        setCantDocumentosASubir(cantprev => cantprev = listaEliminar.length + listaEliminarActualizar.length + listaDocAgregar.length);

                        //documentacion a eliminar
                        if (listaEliminar.length > 0) {
                            listaEliminar.forEach(async (doc) => {
                                await axios.delete(`${endpoint}/documentacion/${doc.id}`).then(respDoc => {
                                    if (respDoc.data.res) {
                                        setContDocumentosSubidos(prevCont => prevCont = prevCont + 1);
                                    }
                                });

                            });
                        }

                        //documentacion que para actualizar o eliminar
                        if (listaEliminarActualizar.length > 0) {
                            const fd = new FormData();
                            listaEliminarActualizar.forEach(async documento => {
                                //elimino el archivo del documento anterior de la bd
                                const response = await axios.delete(`${endpoint}/documentacion/archivo/${documento.id}`);
                                //si existe el archivo lo agrego
                                let doc = listaDocumentos.find(e => e.id === documento.id)
                                doc.proveedor_id = form.id;
                                fd.append(`file${doc.id}`, doc.archivo, doc.nombre);
                                fd.append('id', doc.id.toString());

                                //subimos el nuevo documento
                                const respUpload = await axios.post(`${endpoint}/upload`, fd);
                                fd.delete(`file${doc.id}`);
                                fd.delete('id');
                                doc.ruta_archivo = respUpload.data;
                                doc.nombre_archivo = respUpload.data.split('/')[respUpload.data.split('/').length - 1];
                                doc.created_at = new Date();
                                doc.updated_at = new Date();

                                delete doc.archivo;
                                delete doc.estado;
                                //actualizamos el documentacion
                                await axios.put(`${endpoint}/documentacion/${doc.id}`, doc).then(respDoc => {
                                    if (respDoc.data.res) {
                                        setContDocumentosSubidos(prevCont => prevCont = prevCont + 1);
                                    }
                                });
                            });
                        }
                        if (listaDocAgregar.length > 0) {
                            const fd = new FormData();
                            listaDocAgregar.forEach(async doc => {
                                //subimos el archivo
                                doc.proveedor_id = formularioProveedor.id;
                                fd.append(`file${doc.id}`, doc.archivo, doc.nombre);
                                fd.append('id', doc.id.toString());

                                const respUpload = await axios.post(`${endpoint}/upload`, fd);
                                fd.delete(`file${doc.id}`);
                                fd.delete('id');
                                doc.ruta_archivo = respUpload.data;
                                doc.nombre_archivo = respUpload.data.split('/')[respUpload.data.split('/').length - 1];
                                doc.updated_at = new Date()
                                delete doc.id;
                                delete doc.archivo;
                                delete doc.estado;

                                //registramos la documentacion
                                await axios.post(`${endpoint}/documentacion`, doc).then(respDoc => {
                                    if (respDoc.data.res) {
                                        setContDocumentosSubidos(prevCont => prevCont = prevCont + 1);
                                    }
                                });

                            });
                        }

                        setTextButtonConfirmar('Guardar cambios');
                        setDeshabilitarBotonConfirmar(false);
                        handleNext(activeStep);
                    } else {
                        setTextButtonConfirmar('Guardar cambios');
                        setDeshabilitarBotonConfirmar(false);
                        setDataModal({
                                titulo:'Hubo un error',
                                descripcion:'No fue posible actualizar de los datos del proveedor. Por favor, verifique la información ingresada o intente nuevamente más tarde.',
                                icono:'danger'
                        });
                    }

                });
            } else {
                //CREANDO
                delete formularioProveedor.id;
                //GUARDO EL PROVEEDOR
                setTextButtonConfirmar('Enviando...');
                axios.post(`${endpoint}/proveedores`, formularioProveedor).then(response => {
                    if (response.data.res) {
                        let proveedor = response.data.proveedor;

                        //guardar la documentacion
                        const fd = new FormData();

                        let listaDocs = listaDocumentos.filter(d => d.archivo);
                        setCantDocumentosASubir(prevCont => prevCont = listaDocs.length);

                        listaDocs.forEach(async doc => {
                            doc.proveedor_id = proveedor.id;
                            fd.append(`file${doc.id}`, doc.archivo, doc.nombre);
                            fd.append('id', doc.id.toString());

                            const respUpload = await axios.post(`${endpoint}/upload`, fd);

                            fd.delete(`file${doc.id}`);
                            fd.delete('id');
                            doc.ruta_archivo = respUpload.data;
                            doc.nombre_archivo = respUpload.data.split('/')[respUpload.data.split('/').length - 1];
                            doc.updated_at = new Date()
                            delete doc.id;
                            delete doc.archivo;
                            delete doc.estado;

                            await axios.post(`${endpoint}/documentacion`, doc).then(respDoc => {
                                if (respDoc.data.res) {
                                    setContDocumentosSubidos(prevCont => prevCont = prevCont + 1);
                                }
                            });

                        });
                        //guardar las formas de pago
                        setCantFormasPagoASubir(cantprev => cantprev = listaFormasDePago.length);

                        listaFormasDePago.forEach(async formaPago => {
                            delete formaPago.id
                            formaPago.proveedor_id = proveedor.id;
                            formaPago.created_at = new Date();
                            formaPago.updated_at = new Date();
                            await axios.post(`${endpoint}/formapagos`, formaPago).then(response => {
                                if (response) {
                                    setContFormasPago(prevCont => prevCont = prevCont + 1)
                                }
                            });

                        });
                        handleNext(activeStep);

                    } else {
                        setDeshabilitarBotonConfirmar(false);
                        setTextButtonConfirmar('Confirmar y Enviar');
                        setDataModal({
                            titulo:'Hubo un error',
                            descripcion:'No fue posible crear el proveedor. Por favor, verifique sus datos o intente nuevamente.',
                            icono:'danger'
                        });
                    }
                }).catch(function (error) {
                    console.error('error:', error);
                    setDeshabilitarBotonConfirmar(false);
                    setTextButtonConfirmar('Confirmar y Enviar');
                    setDataModal({
                        titulo:'Hubo un error',
                        descripcion:'No fue posible crear el proveedor. Por favor, verifique sus datos o intente nuevamente. ',
                        icono:'danger'
                    });

                    if (error.response) {
                        console.error('errores:', error.response.data);
                        if (error.response.data) {
                            let mensajeError = error.response.data.message;
                            if (mensajeError.indexOf('proveedores_email_unique') !== -1) {
                                setDataModal({
                                    titulo:'Hubo un error',
                                    descripcion:'El correo electrónico ya está registrado con otro proveedor. Por favor, ingrese un correo electrónico diferente',
                                    icono:'danger'
                                });
                            }
                            if (mensajeError.indexOf('proveedores_cuit_unique') !== -1) {
                                setDataModal({
                                    titulo:'Hubo un error',
                                    descripcion:'El CUIT ingresado ya ha sido registrado por otro proveedor. Por favor, ingrese un CUIT diferente.',
                                    icono:'danger'
                                });
                            }

                        }
                        
                    } else if (error.request) {
                        console.error(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.error('Error', error.message);
                    }
                    console.error(error.config);
                });
            }

        }).catch((errors) => {
            console.error('Hubo errores al obtener el token:', errors);
        })
    }

    //recuperar datos de los otros componentes

    const enviarRecuperarFormasDePago = (listaFormaDePago) => {
        setListaFormasDePago((preLista) => preLista = listaFormaDePago.slice());
    }

    const reinciarAgenteIbb = () => {
        if (+form.agente_percepcion_iibb === 0) {
            let proveedor = form;
            proveedor.agente_percepcion_iibb_descripcion = '';
            Object.assign(form, proveedor);
        }
    }

    const enviarRecuperarDocumentos = (listaDocumentos) => {
        setListaDocumentos((preLista) => preLista = listaDocumentos.slice());
    }

    //validar datos de carga al cargar la pagina
    const faltaCargarDatos = () => {
        return listaRubrosActividades.length === 0 || listaCondicionesIva.length === 0 || listaDocumentos.length === 0 || !paisElegido;
    }

    return (
        <Fragment>
            <HeaderComponent isAdmin={false}/>
            <div className={classes.root}>
                <div className='row mx-0 my-4 d-flex justify-content-center'>
                    <div className='col-sm-12 col-md-8 col-lg-7 border shadow-sm rounded-top p-0'>
                        <Stepper activeStep={activeStep} alternativeLabel style={{ backgroundColor: '#eff2f6' , paddingLeft: '0px', paddingRight: '0px' }}>
                            {steps.map((label) => (
                                <Step key={label} style={{ color: '#ffffff', padding: '0px', margin: '0px' }}>
                                    <StepLabel StepIconProps={{ style: { width: '50px', height: '50px' } }} >{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    {faltaCargarDatos() ?
                        <div className='col-sm-12 col-md-8 col-lg-7 py-3'>
                            <Loader/>
                        </div>
                        :
                        <div className='bg-white col-sm-12 col-md-8 col-lg-7 border border-top-0 rounded-bottom mb-5 d-flex justify-content-center'>
                            {activeStep === steps.length ? (
                                <div className='px-4'>
                                    <div className="container-fluid">
                                        <div className="row m-0">
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div className="card-body">
                                                    <div className="row m-0 d-flex justify-content-center text-center">
                                                        <div className="col-sm-12 col-md-12 col-lg-12 m-2 d-flex justify-content-center">
                                                            {(contDocumentosSubidos < cantDocumentosASubir || contFormasPago < cantFormasPagoASubir) && (
                                                                <Loader/>
                                                            )}
                                                            {+id !== 0 && contDocumentosSubidos === cantDocumentosASubir && contFormasPago === cantFormasPagoASubir && (
                                                                <div className="col-sm-12 col-md-12 col-lg-12 mt-2 mb-4">
                                                                    <div className="card-body">
                                                                        <img src={imagenChecked} className="img-fluid m-2 py-2" width={70} alt="logo" />
                                                                        <h5 className="card-title pt-2 pb-3"> <strong>¡Listo!</strong> Cambios guardados Exitosamente</h5>
                                                                        <Link className='btn fondo btn-lg m-1' to="/">Ir al Inicio</Link>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {+id === 0 && contDocumentosSubidos === cantDocumentosASubir && contFormasPago === listaFormasDePago.length && (
                                                                <div className="col-sm-12 col-md-12 col-lg-12 mt-2 mb-4">
                                                                    <div className="card-body">
                                                                        <img src={imagenChecked} className="img-fluid mx-2 my-3 " width={70} alt="logo" />
                                                                        <h5 className="card-title"> <strong>¡Listo!</strong> Formulario enviado</h5>
                                                                        <p className="card-text text-muted">
                                                                            Se enviaron datos del proveedor
                                                                        </p>
                                                                        <p className="card-text text-muted">
                                                                            Documentación Adjunta
                                                                        </p>
                                                                        <p className="card-text text-muted">
                                                                            Formas de Pago
                                                                        </p>
                                                                        <Link className='btn fondo btn-lg m-1' to="/">Ir al Inicio</Link>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {false && <Button onClick={handleReset}>Reiniciar</Button>}
                                </div>
                            ) : (
                                <div className='col-10'>
                                    <Typography className={classes.instructions}>

                                        {activeStep === 0 &&
                                            <div className='my-3'>
                                                <div className="row m-0 d-flex justify-content-start text-start">
                                                    <div className="col-sm-12 col-md-12 col-lg-12 my-3 p-1 border-bottom">
                                                        <h2>Formulario de registro de proveedor</h2>
                                                        <p className='text-muted'>Complete la información requerida. (*) campos obligatorios</p>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4 col-lg-4 my-3 d-flex flex-row text-end d-none">
                                                        <h5>{paisElegido?.nombre}</h5>
                                                        <img src={logoPais} className="img-fluid m-2 rounded shadow" height={30} width={40} alt="logo" />
                                                    </div>

                                                    {+id !== 0 &&
                                                        <div className="alert alert-success alert-dismissible fade show" role="alert-1">
                                                            <strong>Confirmaste tu cuenta Exitosamente!</strong> Ahora puedes modificar tus datos, documento y formas de pago.
                                                        </div>

                                                    }
                                                    <div className="col-sm-12 col-md-12 col-lg-12 p-0">
                                                        <form onSubmit={handleSubmit}>

                                                            <div className='row m-0 d-flex justify-content-start'>
                                                                <div className="col-sm-12 col-md-12 col-lg-12 my-2">
                                                                    <label className='mb-2' htmlFor='razon_social'>Razón Social *</label>
                                                                    <input
                                                                        className='form-control col-lg-2  my-1'
                                                                        id='razon_social'
                                                                        name='razon_social'
                                                                        placeholder=''
                                                                        type='text'
                                                                        value={form.razon_social}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errorsForm.razon_social &&
                                                                        <div className="invalid-feedback d-block" role="alert">
                                                                            <strong>{errorsForm.razon_social}</strong>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                                    <label className='mb-2' htmlFor='cuit'>CUIT *</label>
                                                                    <input
                                                                        className='form-control col-lg-2  my-1'
                                                                        id='cuit'
                                                                        name='cuit'
                                                                        placeholder='sin puntos ni guiones'
                                                                        type='text'
                                                                        value={form.cuit}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    />

                                                                    {errorsForm.cuit &&
                                                                        <div className="invalid-feedback d-block" role="alert">
                                                                            <strong>{errorsForm.cuit}</strong>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                                    <label className='mb-2' htmlFor='condicion_iva'>Condición IVA *</label>
                                                                    <select
                                                                        id="condicion_iva"
                                                                        className="form-select  my-1"
                                                                        name="condicion_iva"
                                                                        value={form.condicion_iva}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option value='Seleccionar' >Seleccionar...</option>
                                                                        {listaCondicionesIva.map((act, index) => <option key={index} value={act} >{act}</option>)}
                                                                    </select>
                                                                    {errorsForm.condicion_iva &&
                                                                        <div className="invalid-feedback d-block" role="alert">
                                                                            <strong>{errorsForm.condicion_iva}</strong>
                                                                        </div>
                                                                    }

                                                                </div>

                                                                <div className="col-sm-12 col-md-12 col-lg-12 my-2">

                                                                    <label className='mb-2' htmlFor='rubro_actividad_id'>Actividad/Rubro * </label>
                                                                    <select
                                                                        id="rubro_actividad_id"
                                                                        className="form-select  my-1"
                                                                        name="rubro_actividad_id"
                                                                        value={form.rubro_actividad_id}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option value='Seleccionar' >Seleccionar...</option>
                                                                        {listaRubrosActividades.map((act, index) => <option key={index} value={act?.id} >{act?.afip_codigo}-{act?.afip_descripcion}</option>)}
                                                                    </select>
                                                                    {errorsForm.rubro_actividad_id &&
                                                                        <div className="invalid-feedback d-block" role="alert">
                                                                            <strong>{errorsForm.rubro_actividad_id}</strong>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="col-sm-12 col-md-12 col-lg-12 my-2">
                                                                    <label className='mb-2' htmlFor='ingresos_brutos'>Nro Inscripción de Ingresos Brutos * </label>
                                                                    <input
                                                                        className='form-control'
                                                                        name='ingresos_brutos'
                                                                        placeholder=''
                                                                        type='text'
                                                                        value={form.ingresos_brutos}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errorsForm.ingresos_brutos &&
                                                                        <div className="invalid-feedback d-block" role="alert">
                                                                            <strong>{errorsForm.ingresos_brutos}</strong>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="col-sm-12 col-md-12 col-lg-12 my-2">

                                                                    <label className='mb-2' htmlFor='domicilio_comercial'>Domicilio comercial * </label>
                                                                    <input
                                                                        className='form-control col-lg-2  my-1'
                                                                        id='domicilio_comercial'
                                                                        name='domicilio_comercial'
                                                                        placeholder=''
                                                                        type='text'
                                                                        value={form.domicilio_comercial}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errorsForm.domicilio_comercial &&
                                                                        <div className="invalid-feedback d-block" role="alert">
                                                                            <strong>{errorsForm.domicilio_comercial}</strong>
                                                                        </div>
                                                                    }


                                                                </div>
                                                                <div className="col-sm-12 col-md-12 col-lg-12 my-2">
                                                                    <label className='mb-2' htmlFor='nombre_contacto'>Nombre de contacto *</label>
                                                                    <input
                                                                        className='form-control col-lg-2  my-1'
                                                                        id='nombre_contacto'
                                                                        name='nombre_contacto'
                                                                        placeholder=''
                                                                        type='text'
                                                                        value={form.nombre_contacto}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errorsForm.nombre_contacto &&
                                                                        <div className="invalid-feedback d-block" role="alert">
                                                                            <strong>{errorsForm.nombre_contacto}</strong>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="col-sm-12 col-md-12 col-lg-12 mt-2 mb-3">
                                                                    <label className='mb-2' htmlFor='email'>Correo electrónico * </label>
                                                                    <input
                                                                        className='form-control'
                                                                        name='email'
                                                                        placeholder=''
                                                                        type='email'
                                                                        value={form.email}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errorsForm.email &&
                                                                        <div className="invalid-feedback d-block" role="alert">
                                                                            <strong>{errorsForm.email}</strong>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="col-sm-12 col-md-12 col-lg-12 p-0">
                                                                    <div className='row m-0'>
                                                                        <div className="col-sm-12 col-md-6 col-lg-6">

                                                                            <label className='mb-2' htmlFor='cod_area'>Cod Área *</label>
                                                                            <input
                                                                                className='form-control col-lg-2  my-1'
                                                                                id='cod_area'
                                                                                name='cod_area'
                                                                                placeholder='sin puntos ni guiones'
                                                                                type='text'
                                                                                value={form.cod_area}
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                            />
                                                                            {errorsForm.cod_area &&
                                                                                <div className="invalid-feedback d-block" role="alert">
                                                                                    <strong>{errorsForm.cod_area}</strong>
                                                                                </div>
                                                                            }

                                                                        </div>
                                                                        <div className="col-sm-12 col-md-6 col-lg-6">

                                                                            <label className='mb-2' htmlFor='nro_telefono'>Nro Teléfono *</label>
                                                                            <input
                                                                                className='form-control col-lg-2  my-1'
                                                                                id='nro_telefono'
                                                                                name='nro_telefono'
                                                                                placeholder='sin puntos ni guiones'
                                                                                type='text'
                                                                                value={form.nro_telefono}
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                            />
                                                                            {errorsForm.nro_telefono &&
                                                                                <div className="invalid-feedback d-block" role="alert">
                                                                                    <strong>{errorsForm.nro_telefono}</strong>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-12 col-md-6 col-lg-6 my-2 py-2">
                                                                    <label className='mb-2' htmlFor='contribuyente_cm'> ¿Es contribuyente del convenio multilateral? </label> <br />
                                                                    <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                                        <input type="radio" className="btn-check" checked={+form.contribuyente_cm === 1} name="contribuyente_cm" value={1} onChange={handleChange} id="contribuyente_cm_1" />
                                                                        <label className="btn btn-outline-primary" htmlFor="contribuyente_cm_1">SI</label>

                                                                        <input type="radio" className="btn-check" checked={+form.contribuyente_cm === 0} name="contribuyente_cm" value={0} onChange={handleChange} id="contribuyente_cm_2" />
                                                                        <label className="btn btn-outline-primary" htmlFor="contribuyente_cm_2">NO</label>
                                                                    </div>

                                                                </div>
                                                                <div className="col-sm-12 col-md-6 col-lg-6 my-2 py-2">
                                                                    <label className='mb-2' htmlFor='agente_percepcion_iva'> ¿Es Agente de percepción de IVA?</label> <br />
                                                                    <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                                        <input type="radio" className="btn-check" checked={+form.agente_percepcion_iva === 1} name="agente_percepcion_iva" value={1} onChange={handleChange} id="agente_percepcion_iva_1" />
                                                                        <label className="btn btn-outline-primary" htmlFor="agente_percepcion_iva_1">SI</label>

                                                                        <input type="radio" className="btn-check" checked={+form.agente_percepcion_iva === 0} name="agente_percepcion_iva" value={0} onChange={handleChange} id="agente_percepcion_iva_2" />
                                                                        <label className="btn btn-outline-primary" htmlFor="agente_percepcion_iva_2">NO</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 col-lg-6 my-2  py-2">
                                                                    <label className='mb-2' htmlFor='agente_percepcion_iibb'>¿Es Agente de percepción de IIBB? </label> <br />
                                                                    <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                                        <input type="radio" onClick={reinciarAgenteIbb()} className="btn-check" checked={+form.agente_percepcion_iibb === 1} name="agente_percepcion_iibb" value={1} onChange={handleChange} id="agente_percepcion_iibb_1" />
                                                                        <label className="btn btn-outline-primary" htmlFor="agente_percepcion_iibb_1">SI</label>

                                                                        <input type="radio" className="btn-check" checked={+form.agente_percepcion_iibb === 0} name="agente_percepcion_iibb" value={0} onChange={handleChange} id="agente_percepcion_iibb_2" />
                                                                        <label className="btn btn-outline-primary" htmlFor="agente_percepcion_iibb_2">NO</label>
                                                                    </div>
                                                                    {+form.agente_percepcion_iibb === 1 &&
                                                                        <div className="col-sm-12 col-md-6 col-lg-12 my-2">

                                                                            <label htmlFor='agente_percepcion_iibb_descripcion'> Descripción de Jurisdicciones: </label>
                                                                            <input
                                                                                className='form-control my-1'
                                                                                name='agente_percepcion_iibb_descripcion'
                                                                                placeholder=''
                                                                                type='text'
                                                                                value={form.agente_percepcion_iibb_descripcion}
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                            />
                                                                            {errorsForm.agente_percepcion_iibb_descripcion &&
                                                                                <div className="invalid-feedback d-block" role="alert">
                                                                                    <strong>{errorsForm.agente_percepcion_iibb_descripcion}</strong>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>


                                                            </div>

                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {activeStep === 1 &&
                                            <DocumentacionProveedor
                                                proveedor={form}
                                                listaDoc={listaDocumentos}
                                                erroresDocs={listaErroresDocs}
                                                eventEmmiterLista={(listaDocumentos) => enviarRecuperarDocumentos(listaDocumentos)}
                                            />
                                        }
                                        {activeStep === 2 &&
                                            <FormFormaPagoComponent
                                                listaFP={listaFormasDePago}
                                                listaBancosTradicionales={listaBancosTradicionales}
                                                listaTiposCuentas={listaTipoDeCuentas}
                                                listaBancosDigitales={listaBancosDigitales}
                                                eventEmmiterLista={(listaFormaPagos) => enviarRecuperarFormasDePago(listaFormaPagos)}
                                            />
                                        }
                                        {activeStep === 3 &&
                                            <div>
                                                <VistaPreviaProveedor
                                                    proveedor={form}
                                                    pais={paisElegido}
                                                    listaDocumentos={listaDocumentos}
                                                    listaFormasDePago={listaFormasDePago}
                                                    listaRubrosActividades={listaRubrosActividades}
                                                />
                                            </div>
                                        }
                                    </Typography>

                                    <div className='mt-1 mb-3 py-1 px-0 border-top'>
                                        <div className='row m-0 d-flex justify-content-start py-2'>

                                            <div className="col-6 text-lg-start">
                                                {activeStep > 0 &&
                                                    <Button className='btn btn-lg m-1 btn-secondary'
                                                        onClick={handleBack}>
                                                        Atrás
                                                    </Button>
                                                }
                                            </div>
                                            <div className="col-6 text-lg-end pe-0">
                                                <Link className='btn btn-lg m-1 btn-light' to="/">Cancelar</Link>
                                                {activeStep === steps.length - 1 ?
                                                    <Button className='btn btn-lg m-1' disabled={deshabilitarBotonConfirmar} variant="contained" color="primary" onClick={() => enviarYConfirmar()}>
                                                        {textButtonConfirmar}
                                                    </Button>
                                                    :
                                                    <Button className='btn btn-lg m-1' variant="contained" color="primary" onClick={() => handleNext(activeStep)}>
                                                        Siguiente
                                                    </Button>
                                                }
                                            </div>
                                           
                                            {dataModal &&
                                            <div className="col-12 text-lg-end pe-0">
                                                < ModalComponent
                                                    dataModal={dataModal}
                                               />
                                            </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    );
}

export default FormProveedorComponent;


