import React, { Fragment, useEffect, useState } from 'react'
import * as XLSX from 'xlsx/xlsx.mjs';
import MaterialTable from 'material-table'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEye, faDownload } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';
import HeaderComponent from '../../../shared/Header';
import FooterComponent from '../../../shared/Footer';

const endpoint = process.env.REACT_APP_BACKEND_URL;

const ListaProveedores = () => {
  const navigate = useNavigate();
  const [eliminandoProveedor, setEliminandoProveedor] = useState(false);
  const [listaProveedores, setListaProveedores] = useState([]);

  const columns = [
    { title: "ID", field: "id", type: 'numeric', width: "auto" },
    { title: "CUIT", field: "cuit", emptyValue: () => <em>-</em>, width: "auto" },
    { title: "Contacto", field: "nombre_contacto", emptyValue: () => <em>-</em>, width: "auto" },
    { title: "Correo Electrónico", field: 'email', align: 'center', emptyValue: () => <em>-</em>, width: "auto" },
    { title: "Razón Social", field: 'razon_social', emptyValue: () => <em>-</em>, width: "auto" },
    { title: "Actividad/Rubro", field: 'rubro_actividad.afip_descripcion', emptyValue: () => <em>-</em>, width: "auto" }
  ]

  const downloadExcel = () => {
    let listaProveedoresFormaDePago = [];

    listaProveedores.forEach(p => {

      if (p.forma_pagos && p.forma_pagos.length > 0) {
        p.forma_pagos.forEach(fp => {
          let proveedor = Object.assign({}, p);
          proveedor.forma_pagos = fp;
          listaProveedoresFormaDePago.push(proveedor);
        });
      } else {
        p.forma_pagos = null;
        listaProveedoresFormaDePago.push(p);
      }
    });

    const newData = listaProveedoresFormaDePago.map(p => {
      delete p.tableData
      delete p.pais_campos_adicionales_proveedor;
      delete p.codigo_confirmacion;
      delete p.confirmado;
      p.contribuyente_cm === 1 ? p.contribuyente_cm = 'SI' : p.contribuyente_cm = 'NO';
      p.agente_percepcion_iva === 1 ? p.agente_percepcion_iva = 'SI' : p.agente_percepcion_iva = 'NO';
      p.agente_percepcion_iibb === 1 ? p.agente_percepcion_iibb = 'SI' : p.agente_percepcion_iibb = 'NO';

      p.updated_at = new Date(p.updated_at).toLocaleDateString();
      p.created_at = new Date(p.created_at).toLocaleDateString()
      delete p.pais_id;
      p.pais = p.pais.nombre;
      delete p.rubro_actividad_id;
      p.rubro_actividad = p.rubro_actividad.afip_codigo + '-' + p.rubro_actividad.afip_descripcion;

      let fecha = { fecha_creacion: p.created_at, fecha_actualizacion: p.updated_at };
      p = Object.assign(p, fecha);
      delete p.created_at;
      delete p.updated_at;

      let formapago = null;
      if (p.forma_pagos == null) {
        formapago = {
          banco_tipo: null, titular_cuenta: null, cbu_cvu: null, alias: null,
          banco_nombre: null, nro_cuenta: null, cuil_cuit: null,
          cuenta_tipo: null, cuenta_tipo_otro: null, cheque_orden: null, cheque_concepto: null
        }
      } else {
        formapago = p.forma_pagos;
        delete formapago.id;
        delete formapago.proveedor_id;
        delete formapago.id;
        delete formapago.created_at;
        delete formapago.updated_at;
      }

      delete p.forma_pagos;
      p = Object.assign(p, formapago);
      return p;

    })

    const workSheet = XLSX.utils.json_to_sheet(newData)
    const workBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook, workSheet, "Proveedores")
    //Buffer
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
    //Download
    XLSX.writeFile(workBook, "Proveedores.xlsx")

  }

  const elegirProveedor = (proveedor) => {
    delete proveedor.tableData;
    navigate(`/proveedor/detalle/${proveedor.id}`);
  }

  const eliminarDatosProveedor = (proveedor) => {

    Swal.fire({
      title: '¿Estas seguro de eliminar al proveedor?',
      text: "Una vez eliminado, se perderá toda información del proveedor",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Proveedor eliminado',
          text: 'Los datos del proveedor se borraron exitosamente.',
          icon: 'success'
        })
        setEliminandoProveedor(true);
        eliminarDocumentacionProveedor(proveedor.id);
      }
    })
  }

  const eliminarDocumentacionProveedor = async (idProveedor) => {
    await axios.delete(`${endpoint}/documentacion/proveedor/${idProveedor}`).then(respDoc => {
      setListaProveedores(prevLista => prevLista = prevLista.filter(p => p.id !== idProveedor));
      eliminarFormasPagoProveedor(idProveedor);
    });
  }

  const eliminarFormasPagoProveedor = async (idProveedor) => {
    await axios.delete(`${endpoint}/formapagos/proveedor/${idProveedor}`).then(responsefp => {
      eliminarProveedor(idProveedor);
    });
  }
  const eliminarProveedor = async (idProveedor) => {
    await axios.delete(`${endpoint}/proveedores/${idProveedor}`);
    setEliminandoProveedor(false);
    setListaProveedores(prevLista => prevLista = prevLista.filter(p => p.id !== idProveedor));
  }

  useEffect(() => {
    getTodosProveedores();
  }, [])

  const getTodosProveedores = async () => {
    const response = await axios.get(`${endpoint}/proveedores`);
    setListaProveedores(prevLista => prevLista = response.data);
  }

  //configuracion para el materialTable
  const [options] = useState({
    sorting: true, search: true, searchFieldAlignment: "right",
    searchAutoFocus: true, paging: true, pageSizeOptions: [10, 25, 50], pageSize: 10, paginationType: "stepped"
  });

  return (
    <Fragment>
      <HeaderComponent isAdmin={true} />
      <div className='container bg-white mb-0 pt-4 pb-2'>
        <div className='px-3 pt-3 text-start border-bottom'>
          <h1>Listado de Proveedores</h1>
          <p className='text-muted'>En esta sección podrás ver el listado de proveedores registrados hasta el momento.</p>
        </div>
        <div className='m-3 text-start'>
          <h5><span className='badge bg-primary text-white'>Se encontraron {listaProveedores.length} registros</span></h5>
        </div>

        {eliminandoProveedor === true && (
          <div className="spinner-border text-center" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )
        }

        {listaProveedores.length > 0 && (
          <div className='mb-5 p-3'>
            <MaterialTable
              options={options}
              title="Proveedores"
              columns={columns}
              data={listaProveedores.sort((a, b) => b.id - a.id)}
              exportButton={true}

              localization={{ header: { actions: 'Acciones' }, toolbar: { searchPlaceholder: 'Buscar' }, body: { emptyDataSourceMessage: 'No se encontraron resultados' } }}

              actions={[
                {
                  icon: () => <button className='btn fondo d-flex align-items-center justify-content-center'> <FontAwesomeIcon className='p-1' icon={faDownload} /> Descargar</button>,// you can pass icon too
                  tooltip: "Descargar Proveedores",
                  onClick: () => downloadExcel(),
                  isFreeAction: true
                },
                {
                  icon: () => <button className='btn fondo mx-1'> <FontAwesomeIcon icon={faEye} /></button>,// you can pass icon too
                  tooltip: "Ver Datos",
                  onClick: ((columns, data) => elegirProveedor(data)),
                  isFreeAction: false
                },
                {
                  icon: () => <button className='btn btn-danger'><FontAwesomeIcon icon={faTrash} /></button>,// you can pass icon too
                  tooltip: "Eliminar",
                  onClick: ((columns, data) => eliminarDatosProveedor(data)),
                  isFreeAction: false
                }
              ]}
            />
          </div>
        )
        }
      </div>
      <FooterComponent />
    </Fragment>
  )
}

export default ListaProveedores