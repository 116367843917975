import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/el_cuatro_horizontal.png';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem
} from 'reactstrap';

const HeaderComponent = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="bg-dark">
      { props.isAdmin && (
        <Navbar color="dark" className="container" dark expand="md">
        <img src={logo} className="img-fluid m-2" width={300} alt="logo" />
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} className="text-start" navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <Link className="nav-link" to="/admin">Inicio</Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to="/admin/proveedores">Proveedores</Link>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      )}
      { !props.isAdmin && (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid d-flex justify-content-center">
          <img src={logo} className="img-fluid m-2" width={300} alt="logo" />
        </div>
      </nav>
      )}
      
      
    </div>
  );
}

export default HeaderComponent;