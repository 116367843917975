import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { PDFViewer } from "@react-pdf/renderer";
import HeaderComponent from '../../../shared/Header';
import VistaProveedorPdf from './VistaProveedorPdf';

const endpoint = process.env.REACT_APP_BACKEND_URL;

const ImprimirDetalleProveedorComponent = () => {
    const { id } = useParams();
    const [proveedor, setProveedor] = useState();
    const [listaFormaPagos, setListaFormaPagos] = useState([]);
    const [listaFormaPagosTransferencia, setlistaFormaPagosTransferencia] = useState([]);
    const [listaFormaPagosCheques, setlistaFormaPagosCheques] = useState([]);
    const [listaFormaPagosContado, setlistaFormaPagosContado] = useState([]);
    const [listaDocumentacionProveedor, setListaDocumentacionProveedor] = useState([]);
    

    useEffect(() => {
        if (+id !== 0) {
            getProveedor(id);
            getFormasPagosDelProveedor(id);
            getDocumentacionDelProveedor(id);
        }
    }, [])

    const getFormasPagosDelProveedor = async (idProveedor) => {
        const response = await axios.get(`${endpoint}/formapagos/proveedor/${idProveedor}`);
        setListaFormaPagos((prevLista) => prevLista = response.data);
        setlistaFormaPagosTransferencia((prevLista) => prevLista = response.data.filter(fp => fp.pago_tipo.toLowerCase().includes('transferencia')));
        setlistaFormaPagosCheques((prevLista) => prevLista = response.data.filter(fp => fp.pago_tipo.toLowerCase().includes('cheque')));
        setlistaFormaPagosContado((prevLista) => prevLista = response.data.filter(fp => fp.pago_tipo.toLowerCase().includes('contado')));
    }

    const getProveedor = async (id) => {
        await axios.get(`${endpoint}/proveedores/${id}`).then(response => {
            let proveedor = response.data;
            if (proveedor) {
                setProveedor(prevProveedor => prevProveedor = proveedor);
            }
        }).catch((error) => {
            console.error('error al obtener proveedor:', error);
        });

    }

    const getDocumentacionDelProveedor = async (idProveedor) => {
        const response = await axios.get(`${endpoint}/documentacion/proveedor/${idProveedor}`);
        setListaDocumentacionProveedor((prevLista) => prevLista = response.data);
        
    }
    
    return (
        <Fragment>
            <HeaderComponent />
            <div className='container-fluid text-start mb-5'>
                <Link className='btn btn-danger text-white btn-lg m-1' to="/admin"> <FontAwesomeIcon icon={faRotateLeft} /> Volver al listado</Link>
                {proveedor && listaFormaPagos.length > 0 && <PDFViewer style={{ width: "100%", height: "90vh" }}>
                    <VistaProveedorPdf
                        proveedor={proveedor} pais={proveedor.pais} mostrarFechas={true}
                        listaFormaPagos={listaFormaPagos}
                        listaFormaPagosTransferencia={listaFormaPagosTransferencia}
                        listaFormaPagosCheques={listaFormaPagosCheques}
                        listaFormaPagosContado={listaFormaPagosContado}
                        listaDocumentacionProveedor={listaDocumentacionProveedor}
                    />
                </PDFViewer>}
            </div>
        </Fragment>

    )
}

export default ImprimirDetalleProveedorComponent